/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    p: "p",
    h3: "h3",
    strong: "strong",
    em: "em"
  }, _provideComponents(), props.components), {Boss, Skill, Trait, Specialization, Boon, Warning, Item} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Boss, {
    name: "LightAi",
    video: "lxfXSd9Y8oE",
    videoCreator: "BlackHawk [dT]",
    foodId: "92456",
    utilityId: "48917",
    healId: "31914",
    utility1Id: "40498",
    utility2Id: "12498",
    utility3Id: "12537",
    eliteId: "45717",
    weapon1MainAffix: "Viper",
    weapon1MainType: "Short Bow",
    weapon1MainSigil1: "Bursting",
    weapon1MainSigil2: "Earth",
    weapon1MainInfusion1Id: "37130",
    weapon2OffAffix: "Viper",
    weapon2OffType: "Torch",
    weapon2OffSigil: "Earth",
    weapon2OffInfusionId: "37130",
    weapon2MainAffix: "Viper",
    weapon2MainType: "Dagger",
    weapon2MainSigil1: "Bursting",
    weapon2MainInfusion1Id: "37130",
    video: "lxfXSd9Y8oE",
    videoCreator: "BlackHawk [dT]",
    foodId: "92456",
    utilityId: "48917",
    healId: "31914",
    utility1Id: "40498",
    utility2Id: "12498",
    utility3Id: "12537",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37130",
    weapon2OffInfusionId: "37130",
    weapon2MainInfusion1Id: "37130",
    weapon1MainId: "74569",
    weapon1MainSigil1Id: "44944",
    weapon1MainSigil2Id: "24560",
    weapon2MainId: "74179",
    weapon2MainSigil1Id: "44944",
    weapon2OffId: "76271",
    weapon2OffSigilId: "24560"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Pet: ", React.createElement(Skill, {
    id: "41206",
    disableText: true,
    id: "41206"
  }), " Bristleback for dps. ", React.createElement(Skill, {
    id: "46432",
    disableText: true,
    id: "46432"
  }), " Warthog if your group needs the CC."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(Trait, {
    id: "2128",
    id: "2128"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Trait, {
    id: "1912",
    id: "1912"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " can be replaced with ", React.createElement(Skill, {
    id: "45970",
    id: "45970"
  }), " this allows your ", React.createElement(Specialization, {
    name: "Renegade"
  }), " to lower their boon duration and deal substantially more DPS! Alternatively if you have more than one ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), ", one can drop ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), ". Just remember to sync up ", React.createElement(Skill, {
    id: "45970",
    id: "45970"
  }), " with ", React.createElement(Skill, {
    id: "45537",
    id: "45537"
  }), " so ", React.createElement(Boon, {
    name: "Alacrity"
  }), " doesn't drop."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Quick shot",
    id: "12517"
  }), " and ", React.createElement(Skill, {
    name: "Instinctive Engage",
    id: "46123"
  }), " are great movement abilities for following Ai when dashing and also to dodge suicidal teammates."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "There is an invisible hitbox in the center of the arena so use ", React.createElement(Skill, {
    id: "41206",
    id: "41206"
  }), " there for a big DPS."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "For this encounter swapping shortbow for Axe/Dagger can work well in high DPS groups due to Ai getting phased at the side and not dashing much."), "\n"), "\n")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "The following guide assumes your group will CC Ai at the side of the arena; and will phase at the side or shortly after, Your comp and pet choice will change the amount of CC required and skill usage, so be prepared to adjust your rotation.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Boons get removed upon entering combat here, so stacking them is pointless. Effects will stay though so take advantage of that and precast what you can.")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Optionally before pre-stacking you can get into combat by jumping off the side of the map. This allows you to pre-stack on weapon swap sigils such as ", React.createElement(Item, {
    id: "24609",
    id: "24609"
  }), " and ", React.createElement(Item, {
    id: "24599",
    id: "24599"
  }), ". While in combat you can also precast ", React.createElement(Trait, {
    name: "Poison MAster",
    id: "1701"
  }), " by using a ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " ability."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Sharpen Spines",
    id: "45479"
  }), " while merged with Bristleback then swap to your desired pet and enter ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " (If you are staying with Bristleback you can cast ", React.createElement(Skill, {
    name: "Sharpen Spines",
    id: "45479"
  }), " a second time after taking the ", React.createElement(_components.em, null, "Mistlock Singularity"), ")"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "With ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " selected, use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " and dodge twice."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you are dodging cast ", React.createElement(Skill, {
    name: "Vulture stance",
    id: "40498"
  }), ", ", React.createElement(Skill, {
    name: "sharpening stone",
    id: "12537"
  }), ", ", React.createElement(Skill, {
    name: "one wolf pack",
    id: "45717"
  }), " and ", React.createElement(Skill, {
    name: "moa stance",
    id: "45970"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take mistlock ", React.createElement(_components.em, null, "Mistlock Singularity"), " and swap any skills back you have changed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " again"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you walk into the arena make sure to stack ", React.createElement(Trait, {
    id: "1912",
    id: "1912"
  }), " for some extra initial damage."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " so it spawns towards the middle of the arena."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Air Phase")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you get into combat cast ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), ", ", React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " and ", React.createElement(Skill, {
    name: "Sharpening Stone",
    id: "12537"
  }), ". Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), ", ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), ", ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and follow Ai to the side."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you reach the side call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), " and CC with ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As they come off cool-down use ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " and ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap back to Shortbow and follow Ai to the middle of the arena calling ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you as soon as you can. Use your Shortbow and ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills until the boss phases, ideally before the expanding AoE's fill."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If Ai hasn't phased use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back so you don't get demerged, and continue with your rotation swapping back to Dagger/Torch after you get back to the middle."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Fire Phase")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Ideally your group will use a Xera portal to get back to the middle to DPS Ai quickly. If you are running ", React.createElement(Trait, {
    name: "Quickdraw",
    id: "1064"
  }), " make sure someone else uses their portal as the trait will proc when you open portal which isn't ideal.")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Start this phase on Dagger/Torch"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As the split phase ends take a Xera portal back to the middle casting ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " as you go through."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Once in the middle use ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), ", ", React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " and ", React.createElement(Skill, {
    name: "Sharpening Stone",
    id: "12537"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and swap to Shortbow."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), ", ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), " and ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills.Keep DPSing until you need to head to the safe spot by the Meteor. Once it is safe to leave follow Ai to the side, calling ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you reach the side use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " and ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " to CC and call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), " and ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and swap to Dagger/Torch and continue with your rotation until Ai phases."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Water Phase")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "There is not much you can do in the split phase here, so just make sure to keep an eye on tethers and to give/take them if you or someone else is close to 9 stacks. If your ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " has died remember to summon it towards the end of the split phase.")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As Ai becomes vulnerable use ", React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " and ", React.createElement(Skill, {
    name: "Sharpening Stone",
    id: "12537"
  }), ". Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), ", ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), ", ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and follow Ai to the side."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you reach the side call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), " and CC with ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), ", and cast ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As they come off cool-down use ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " and ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap back to Shortbow and follow Ai to the middle of the arena calling ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you as soon as you can. Use your Shortbow and ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills until the boss phases, ideally before the expanding AoE's fill."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "If Ai doesn't phase use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back so you don't get demerged, and continue with your rotation swapping back to Dagger/Torch after you get back to the middle."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Dark Ai, Keeper of the Peak")), "\n", React.createElement(Boss, {
    name: "DarkAi",
    video: "WkU20J-00gQ",
    videoCreator: "BlackHawk [dT]",
    foodId: "92456",
    utilityId: "48917",
    healId: "31914",
    utility1Id: "40498",
    utility2Id: "12498",
    utility3Id: "12537",
    eliteId: "45717",
    weapon1MainAffix: "Viper",
    weapon1MainType: "Short Bow",
    weapon1MainSigil1: "Bursting",
    weapon1MainSigil2: "Earth",
    weapon1MainInfusion1Id: "37130",
    weapon2OffAffix: "Viper",
    weapon2OffType: "Torch",
    weapon2OffSigil: "Earth",
    weapon2OffInfusionId: "37130",
    weapon2MainAffix: "Viper",
    weapon2MainType: "Dagger",
    weapon2MainSigil1: "Bursting",
    weapon2MainInfusion1Id: "37130",
    video: "WkU20J-00gQ",
    videoCreator: "BlackHawk [dT]",
    foodId: "92456",
    utilityId: "48917",
    healId: "31914",
    utility1Id: "40498",
    utility2Id: "12498",
    utility3Id: "12537",
    eliteId: "45717",
    weapon1MainInfusion1Id: "37130",
    weapon2OffInfusionId: "37130",
    weapon2MainInfusion1Id: "37130",
    weapon1MainId: "74569",
    weapon1MainSigil1Id: "44944",
    weapon1MainSigil2Id: "24560",
    weapon2MainId: "74179",
    weapon2MainSigil1Id: "44944",
    weapon2OffId: "76271",
    weapon2OffSigilId: "24560"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Pet: ", React.createElement(Skill, {
    id: "41206",
    disableText: true,
    id: "41206"
  }), " Bristleback for dps. ", React.createElement(Skill, {
    id: "46432",
    disableText: true,
    id: "46432"
  }), " Warthog if your group needs the CC."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take ", React.createElement(Trait, {
    id: "2128",
    id: "2128"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Trait, {
    id: "1912",
    id: "1912"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " can be replaced with ", React.createElement(Skill, {
    id: "45970",
    id: "45970"
  }), " this allows your ", React.createElement(Specialization, {
    name: "Renegade"
  }), " to lower their boon duration and deal substantially more DPS! Alternatively if you have more than one ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), ", one can drop ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), ". Just remember to sync up ", React.createElement(Skill, {
    id: "45970",
    id: "45970"
  }), " with ", React.createElement(Skill, {
    id: "45537",
    id: "45537"
  }), " so ", React.createElement(Boon, {
    name: "Alacrity"
  }), " doesn't drop."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Quick shot",
    id: "12517"
  }), " and ", React.createElement(Skill, {
    name: "Instinctive Engage",
    id: "46123"
  }), " are great movement abilities for following Ai when dashing and also to dodge suicidal teammates."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Using ", React.createElement(Skill, {
    id: "41206",
    id: "41206"
  }), " when multiple targets are around is a big DPS increase so make sure you have it available to use as the Fears close in."), "\n"), "\n")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "The following guide assumes your group will CC Ai at the side of the arena; There is a fair amount of RNG on this encounter so don't blindly follow the guide. Your comp and pet choice will change the amount of CC required and skill usage, so be prepared to adjust your rotation.")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Boons get removed upon entering combat here, so stacking them is pointless. Effects will stay though so take advantage of that and precast what you can.")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Optionally before pre-stacking you can get into combat by jumping off the side of the map. This allows you to pre-stack on weapon swap sigils such as ", React.createElement(Item, {
    id: "24609",
    id: "24609"
  }), " and ", React.createElement(Item, {
    id: "24599",
    id: "24599"
  }), ". While in combat you can also precast ", React.createElement(Trait, {
    name: "Poison MAster",
    id: "1701"
  }), " by using a ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " ability."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(Skill, {
    name: "Sharpen Spines",
    id: "45479"
  }), " while merged with Bristleback then swap to your desired pet and enter ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " (If you are staying with Bristleback you can cast ", React.createElement(Skill, {
    name: "Sharpen Spines",
    id: "45479"
  }), " a second time after taking the ", React.createElement(_components.em, null, "Mistlock Singularity"), ")"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "With ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " selected, use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " and dodge twice."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you are dodging cast ", React.createElement(Skill, {
    name: "Vulture stance",
    id: "40498"
  }), ", ", React.createElement(Skill, {
    name: "sharpening stone",
    id: "12537"
  }), ", ", React.createElement(Skill, {
    name: "one wolf pack",
    id: "45717"
  }), " and ", React.createElement(Skill, {
    name: "moa stance",
    id: "45970"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  })), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Take mistlock ", React.createElement(_components.em, null, "Mistlock Singularity"), " and swap any skills back you have changed."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " again"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you walk into the arena make sure to stack ", React.createElement(Trait, {
    id: "1912",
    id: "1912"
  }), " for some extra initial damage."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " so it spawns towards the middle of the arena."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "100% - 66%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you get into combat cast ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), ", ", React.createElement(Skill, {
    name: "Vulture Stance",
    id: "40498"
  }), " and ", React.createElement(Skill, {
    name: "Sharpening Stone",
    id: "12537"
  }), ". Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), ", ", React.createElement(Skill, {
    name: "Crippling Shot",
    id: "12507"
  }), ", ", React.createElement(Skill, {
    name: "Poison Volley",
    id: "12468"
  }), " and follow Ai to the side."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As you reach the side call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), " and CC with ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Keep attacking until Ai dashes away, so use ", React.createElement(Skill, {
    name: "Instinctive Engage",
    id: "46123"
  }), " to follow and call ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " to you with ", React.createElement(Skill, {
    name: "Solar Flare",
    id: "12597"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " and ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), ", then swap to Shortbow using your Shortbow and ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills until the boss phases."), "\n"), "\n"), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "Your rotation from here will depend on your groups DPS and Ai's RNG. In general remember to dodge as much as you can to keep ", React.createElement(Trait, {
    name: "Light on your Feet",
    id: "1912"
  }), " active and use your mobility skills to keep attacking Ai as much as you can. ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " will probably die a couple of times so make sure you re-summon it as soon as you can, and call it to you when you move. Finally CC Ai when you can, but also remember to use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " and ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " (If you run Warthog) to keep up ", React.createElement(Trait, {
    name: "Twice as Vicious",
    id: "2127"
  }), ".")), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "66% - 33%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Follow Ai to the middle of the arena summoning ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " as soon as you can and camp Shortbow until the first Sorrow spawns. Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " to CC."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As they come off cool-down use ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " and ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "At this point Ai will either stay in the middle or dash to the side. If Ai dashes be prepared to follow."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Quickly swap to Shortbow and use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back as Fears will be closing in, and camp Shortbow until Ai reaches 33%."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Help to CC Ai if you can, but don't waste cool-downs you will need to CC the Sorrows at 33%. Be prepared to split if you don't reach 33% before the agony field appears in the middle."), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "33% - 0%")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Summon ", React.createElement(Skill, {
    name: "Sun Spirit",
    id: "12498"
  }), " if it has died."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Concussion Shot",
    id: "12508"
  }), " and ", React.createElement(Skill, {
    id: "46432",
    id: "46432"
  }), " if you are merged with Warthog to CC a Sorrow."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "One Wolf Pack",
    id: "45717"
  }), " and swap to Dagger/Torch and use ", React.createElement(Skill, {
    name: "Bonfire",
    id: "12504"
  }), ", ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), ", ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), " and your ", React.createElement(Skill, {
    id: "42944",
    id: "42944"
  }), " skills."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As they come off cool-down use ", React.createElement(Skill, {
    name: "Double Arc",
    id: "43536"
  }), " and ", React.createElement(Skill, {
    name: "Throw Torch",
    id: "12635"
  }), "."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Quickly swap to Shortbow and use ", React.createElement(Skill, {
    name: "Quick Shot",
    id: "12517"
  }), " to dodge back as Fears will be closing in and once they are gone move back in continuing with your normal rotation until Ai is dead. If Ai dashes to the side follow and help to CC if you can."), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
